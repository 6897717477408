import { Box, Button, Container, Grid, Typography } from "@mui/material"
import Icons from "../../assets/js/icons"
import images from "../../assets/images"
import { Link } from "react-router-dom"
import { Bounce, Fade, Slide } from "react-awesome-reveal"

function About() {

  return (
    <Box id="about" component="section" className="about-sec py-14">
      <Container>
        <Box className="pb-10">
          <Button
            component="div"
            variant="text"
            disableRipple
            startIcon={
              <Box className="border-[1px] rounded-full p-1 border-dashed bg-transparent border-jade-500">
                <Icons.MuiAboutUs className="!text-3xl aspect-square" />
              </Box>
            }
            className="sec-title"
          >About us</Button>
          <Typography variant="h3" className="main-title">
            <Fade className="!whitespace-normal" delay={500} cascade damping={1e-1}>
              From Pipes to Perfection, We Handle It All
            </Fade>
          </Typography>
        </Box>
        <Box className="p-4">
          <Grid container columnSpacing={4}>
            <Grid item xs={12} md={6}>
              <Box className="p-4 relative">
                <img src={images.img_2} alt="img" />
                <Box className="overlay bg-[#0bad734d] z-10 rounded-md" />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className="h-full pt-6">
                <Slide direction="right">
                  <Typography variant="body2" className="content-1">At 4HOMES, we provide a quality plumbing service with a no fuss attitude. We believe anything can be achieved.</Typography>
                  <Typography variant="body2" className="content-1 mt-4">Over the past 25 years we have been working alongside strata and realestate companies to provide a quality fix to a whole array of plumbing issues.</Typography>
                  <Typography variant="body2" className="content-1 mt-4">We also work closely with our residential clients to produce fantastic results,in Bathroom, kitchen and Laundry renovations.</Typography>
                </Slide>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className="p-4">
          <Grid container columnSpacing={4}>
            <Grid item xs={12} md={6} className="order-2 sm:order-1">
              <Box className="h-full pt-6 flex flex-col">
                <Slide direction="left">
                  <Typography variant="body2" className="content-1">We Pride ourselves on giving our clients a great experience.</Typography>
                  <Typography variant="body2" className="content-1 mt-4">Whether you need a plumbing problem fixed or you need some renovation plumbing done.<br />Call us today<br />I am sure we can help you.</Typography>
                  <Typography variant="body2" className="content-1 mt-4">Thank you</Typography>
                </Slide>
                <Box className="mt-auto">
                  <Bounce delay={1500}>
                    <Button LinkComponent={Link} to="tel:0416249422" variant="contained" size="large" className="primary-btn py-4 px-8 text-2xl mt-6">Call now</Button>
                  </Bounce>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} className="order-1 sm:order-2">
              <Box className="p-4 relative">
                <Grid container columnSpacing={3}>
                  <Grid item xs={6}>
                    <Box className="h-[300px] mt-10">
                      <img src={images.img_1} alt="img" />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box className="h-[300px] mb-10">
                      <img src={images.image_7} alt="img" />
                    </Box>
                  </Grid>
                </Grid>
                <Box className="overlay bg-[#0bad734d] z-10 rounded-md" />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}

export default About