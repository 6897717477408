import { Box, Button, Card, CardContent, Container, Grid, Typography } from "@mui/material"
import { Fade, Zoom } from "react-awesome-reveal"
import Icons from "../../assets/js/icons"
import images from "../../assets/images"
import { Link } from "react-router-dom"

function Services() {

  return (
    <Box id="services" component="section" className="py-14">
      <Container>
        <Box className="pb-10">
          <Button
            component="div"
            variant="text"
            disableRipple
            startIcon={
              <Box className="border-[1px] rounded-full p-1 border-dashed bg-transparent border-jade-500">
                <Icons.MuiPlumbingTool className="!text-3xl aspect-square" />
              </Box>
            }
            className="sec-title"
          >Services</Button>
          <Typography variant="h3" className="main-title">
            <Fade className="!whitespace-normal" delay={500} cascade damping={1e-1}>
              From Fixes to Full Installs, We've Got Your Plumbing Needs Covered
            </Fade>
          </Typography>
        </Box>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Zoom delay={100} className="h-full">
                <Card className="single-service transition-all h-full gap-0">
                  <Box sx={{ height: { xs: "250px", md: "200px" } }} className="p-2">
                    <img alt="service-1" src={images.img_2} className="h-full w-full object-cover rounded-md" />
                  </Box>
                  <CardContent className="flex flex-col gap-3 h-full">
                    <Box className="flex items-center">
                      <Box className="grid place-content-center h-[40px] w-[40px] border-[1px] border-dashed border-jade-500 rounded-full">
                        <Icons.MuiPlumbingTool className="!text-3xl text-jade-500" />
                      </Box>
                      <Typography className="text-[26px] ms-3 font-ff-lato font-medium text-jade-500">Maintenance</Typography>
                    </Box>
                    <Box className="flex-grow">
                      <Typography className="font-ff-lato font-medium leading-[1.2] pb-[2px]">With over 25 years in the plumbing game, we are yet to come up against a plumbing problem we cant fix.</Typography>
                    </Box>
                    <Button LinkComponent={Link} to="tel:0416249422" variant="contained" className="primary-btn w-full">Call now</Button>
                  </CardContent>
                </Card>
              </Zoom>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Zoom delay={100} className="h-full">
                <Card className="single-service transition-all h-full gap-0">
                  <Box sx={{ height: { xs: "250px", md: "200px" } }} className="p-2">
                    <img alt="service-2" src={images.img_1} className="h-full w-full object-cover rounded-md" />
                  </Box>
                  <CardContent className="flex flex-col gap-3 h-full">
                    <Box className="flex items-center">
                      <Box className="grid place-content-center h-[40px] w-[40px] border-[1px] border-dashed border-jade-500 rounded-full">
                        <Icons.MuiWorkCase className="!text-3xl text-jade-500" />
                      </Box>
                      <Typography className="text-[26px] ms-3 font-ff-lato font-medium text-jade-500">Renovations</Typography>
                    </Box>
                    <Box className="flex-grow">
                      <Typography className="font-ff-lato font-medium leading-[1.2] pb-[2px]">Whether you are renovating your Bathroom, Kitchen or laundry.</Typography>
                      <Typography className="font-ff-lato font-medium leading-[1.2] pb-[2px]">We take the stress and hassle out of the process for you.</Typography>
                      <Typography className="font-ff-lato font-medium leading-[1.2] pb-[2px]">From demolition stage to fit out,we take care of it all.</Typography>
                      <Typography className="font-ff-lato font-medium leading-[1.2] pb-[2px]">All of our tradies are licensed and ready to get started on your project.</Typography>
                    </Box>
                    <Button LinkComponent={Link} to="tel:0416249422" variant="contained" className="primary-btn w-full">Call now</Button>
                  </CardContent>
                </Card>
              </Zoom>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}

export default Services