import { Box, Container, IconButton, Typography } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Icons from '../../assets/js/icons'

const menus = [
  { name: "about", path: "/about" },
  { name: "services", path: "/services" },
  { name: "contact", path: "/contact" }
]

function Header() {
  const { pathname } = useLocation()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (window.innerWidth > 768) {
      setOpen(true)
    }
    window.addEventListener('resize', (e) => {
      e.target.innerWidth > 769 ?
        setOpen(true) : setOpen(false)
    })
  }, [])

  return (
    <Box id="Header" className="py-4 shadow bg-jade-500">
      <Container>
        <Box className="block md:flex items-center gap-8">
          <Box className="flex md:auto items-center justify-between w-full">
            <Box sx={{ pr: 2 }} className="nav-brand uppercase">
              <Link to="/" className="p-2">
                <Box className="flex">
                  <Typography component="span" className="block text-5xl font-bold me-1 font-ff-montserrat leading-[1] text-white">4</Typography>
                  <Box>
                    <Typography component="span" className="block text-3xl font-medium font-ff-montserrat leading-[1] text-white">Homes</Typography>
                    <Typography component="span" className="block font-medium font-ff-lato leading-[1] tracking-[3px] text-white">pty ltd</Typography>
                  </Box>
                </Box>
              </Link>
            </Box>
            <IconButton className="-rotate-90 flex md:hidden text-jade-50" onClick={() => { setOpen(!open) }}>
              {open ? <Icons.MuiClose1 /> : <Icons.MuiMenu1 />}
            </IconButton>
          </Box>

          <nav className="navbar">
            <Box className={`flex text-center gap-4 md:text-start flex-col md:flex-row ${open ? "" : "hidden"}`}>
              <Link to="/home" className={`nav-link transition-all ${(pathname === "/" || pathname === "/home") && "active"}`} onClick={() => {window.innerWidth < 768 && setOpen(false) }}>home</Link>
              {menus?.map((menu, i) => (
                <Link key={`menu-${i}`} to={menu?.path} className={`nav-link transition-all ${pathname.includes(menu?.path) && "active"}`} onClick={() => {window.innerWidth < 768 && setOpen(false) }}>{menu?.name}</Link>
              ))
              }
            </Box>
          </nav>
        </Box>
      </Container>
    </Box>
  )
}

export default Header