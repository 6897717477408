import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material"
import { Bounce, Fade } from "react-awesome-reveal"
import images from "../../assets/images"
import { Link } from "react-router-dom"

function Home() {

  return (
    <Box id="Home">
      <Box id="home" component="section" className="relative z-[1] hero-sec py-24 bg-cover bg-center" sx={{ backgroundImage: `url(${images?.img_1})` }}>
        <Box className="overlay bg-[#00000080]" />
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Box className="flex flex-col">
                <Box className="flex-grow">
                  <Typography variant="h1" className="hero-title">
                    <Fade className="!whitespace-normal" cascade damping={1e-1}>
                      Plumbing Maintenance and Renovations
                    </Fade>
                  </Typography>
                  <Typography component="div" variant="body1" className="hero-subtitle">
                    <Fade className="!whitespace-normal" cascade damping={1e-1}>
                      Bathrooms | Kitchens | Laundrys
                    </Fade>
                  </Typography>
                </Box>
                <Stack direction="row" className="gap-4 mt-8">
                  <Bounce delay={1500}>
                    <Button LinkComponent={Link} to="mailto:admin@4homes.com.au" variant="contained" size="large" className="primary-btn py-4 px-8 text-2xl min-w-[150px]">E-mail</Button>
                  </Bounce>
                  <Bounce delay={1500}>
                    <Button LinkComponent={Link} to="tel:0416249422" variant="contained" size="large" className="primary-btn py-4 px-8 text-2xl min-w-[150px]">Call</Button>
                  </Bounce>
                </Stack>
                <Box className="mt-8">
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}


export default Home
