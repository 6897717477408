import image_1 from "./banner-1.jpg"
import image_2 from "./banner-2.jpg"
import image_3 from "./banner-3.jpg"
import image_4 from "./banner-4.jpg"
import image_5 from "./banner-5.jpg"
import image_6 from "./banner-6.jpg"
import image_7 from "./banner-7.jpg"
import img_1 from "./img-1.jpg"
import img_2 from "./img-2.jpg"
// import image_6 from "./banner-6.jpg"

const images = {
  image_1,
  image_2,
  image_3,
  image_4,
  image_5,
  image_6,
  image_7,
  img_1,
  img_2,
}

export default images