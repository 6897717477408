import { Box, Button, Container, Stack, Typography } from "@mui/material"
import images from "../../assets/images"
import { Bounce, Slide } from "react-awesome-reveal"
import { Link } from "react-router-dom"

function Contact() {

  return (
    <Box id="contact" component="section" className="py-10 relative z-10 bg-cover bg-center" sx={{ backgroundImage: `url(${images?.image_1})` }}>
      <Box className="overlay bg-gradient-to-r from-jade-300 to-[#ffffffba]" />
      <Container>
        <Box className="pb-5">
          <Typography className="font-ff-montserrat text-5xl uppercase font-medium text-white">get in touch</Typography>
          <Typography className="font-ff-lato text-lg max-w-[650px] text-jade-700">At 4HOMES, we provide a quality plumbing service with a no fuss attitude. We believe anything can be achieved.</Typography>
        </Box>
        <Box className="py-5">
          <Slide direction="right">
            <Stack direction="row" gap={4}>
              <Bounce delay={1500}>
                <Button LinkComponent={Link} to="mailto:admin@4homes.com.au" variant="contained" size="large" className="primary-btn py-4 px-8 text-2xl min-w-[150px]">E-mail</Button>
              </Bounce>
              <Bounce delay={1500}>
                <Button LinkComponent={Link} to="tel:0416249422" variant="contained" size="large" className="primary-btn py-4 px-8 text-2xl min-w-[150px]">Call</Button>
              </Bounce>
            </Stack>
            {/* <Paper component="form" className="max-w-[400px] shadow-none p-6">
              <Grid container rowSpacing={3}>
                <Grid item xs={12}>
                  <Box className="py-1 px-3 rounded-lg bg-jade-100">
                    <CustomInput
                      type="text"
                      name="name"
                      label="name"
                      id="nameField"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className="py-1 px-3 rounded-lg bg-jade-100">
                    <CustomInput
                      type="email"
                      name="email"
                      label="email address"
                      id="emailField"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className="py-1 px-3 rounded-lg bg-jade-100 custom-textarea">
                    <CustomInput
                      type="textarea"
                      name="message"
                      label="message"
                      id="messageField"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" className="primary-btn uppercase w-[50%]">send</Button>
                </Grid>
              </Grid>
            </Paper> */}
          </Slide>
        </Box>
      </Container>
    </Box>
  )
}

export default Contact