import { RouterProvider } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import routes from './routes/route';
import "./assets/css/index.css"
import { StyledEngineProvider } from '@mui/material';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StyledEngineProvider injectFirst>
    <RouterProvider router={routes} />
  </StyledEngineProvider>
);