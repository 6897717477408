import { createRoutesFromElements, createBrowserRouter, Route, } from "react-router-dom";
import Layout from "../components/frontend/Layout";
import Services from "../pages/frontend/Services";
import Contact from "../pages/frontend/Contact";
import About from "../pages/frontend/About";
import Home from "../pages/frontend/Home";

const routes = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route path="" element={<Home />} />
      <Route path="home" element={<Home />} />
      <Route path="about" element={<About />} />
      <Route path="contact" element={<Contact />} />
      <Route path="services" element={<Services />} />
    </Route>
  )
)

export default routes