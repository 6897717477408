import MiscellaneousServicesRoundedIcon from '@mui/icons-material/MiscellaneousServicesRounded';
import CleaningServicesRoundedIcon from '@mui/icons-material/CleaningServicesRounded';
import WorkOutlineRoundedIcon from '@mui/icons-material/WorkOutlineRounded';
import WaterDamageRoundedIcon from '@mui/icons-material/WaterDamageRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import LandscapeRoundedIcon from '@mui/icons-material/LandscapeRounded';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import PlumbingRoundedIcon from '@mui/icons-material/PlumbingRounded';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import Groups3RoundedIcon from '@mui/icons-material/Groups3Rounded';
import RoofingRoundedIcon from '@mui/icons-material/RoofingRounded';
import { Facebook, Instagram, LinkedIn } from '@mui/icons-material';
import ShowerRoundedIcon from '@mui/icons-material/ShowerRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CallRoundedIcon from '@mui/icons-material/CallRounded';
import MoodRoundedIcon from '@mui/icons-material/MoodRounded';

const Icons = {
  MuiServices: MiscellaneousServicesRoundedIcon,
  MuiCleaning: CleaningServicesRoundedIcon,
  MuiWaterDamage: WaterDamageRoundedIcon,
  MuiPlumbingTool: PlumbingRoundedIcon,
  MuiWorkCase: WorkOutlineRoundedIcon,
  MuiPlay1: PlayArrowRoundedIcon,
  MuiAboutUs: Groups3RoundedIcon,
  MuiMenu1: BarChartRoundedIcon,
  MuiHeart: FavoriteRoundedIcon,
  MuiClose1: CancelRoundedIcon,
  MuiShower: ShowerRoundedIcon,
  MuiVisit: RoofingRoundedIcon,
  MuiImg: LandscapeRoundedIcon,
  MuiPerson: PersonRoundedIcon,
  MuiContact: CallRoundedIcon,
  MuiFeed: MoodRoundedIcon,
  MuiInstagram: Instagram,
  MuiFacebook: Facebook,
  MuiLinkedIn: LinkedIn,
}

export default Icons