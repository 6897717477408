import { Box, Container, Grid, IconButton, Stack, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import Icons from "../../assets/js/icons"

function Footer() {
  const year = new Date().getFullYear()

  return (
    <Box id="Footer" className="bg-jade-500">
      <Container>
        <Box className="py-10 text-center sm:text-left">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Link to="/" className="p-2 mb-2">
                <Box className="flex">
                  <Typography component="span" className="block text-5xl font-bold me-1 font-ff-montserrat leading-[1] text-white">4</Typography>
                  <Box>
                    <Typography component="span" className="block text-3xl font-medium font-ff-montserrat leading-[1] text-white">Homes</Typography>
                    <Typography component="span" className="block font-medium font-ff-lato leading-[1] tracking-[3px] uppercase text-white">pty ltd</Typography>
                  </Box>
                </Box>
              </Link>
              <Typography className="font-medium font-ff-lato leading-[1.2] tracking-[0.5px] max-w-[380px] mx-auto sm:mx-0 text-white">At 4HOMES, we provide a quality plumbing service with a no fuss attitude. We believe anything can be achieved.</Typography>
              <Stack direction="row" spacing={1} className="pt-2 justify-center sm:justify-start">
                <IconButton className="nav-link aspect-square" LinkComponent={Link} to="/"><Icons.MuiFacebook /></IconButton>
                <IconButton className="nav-link aspect-square" LinkComponent={Link} to="/"><Icons.MuiInstagram /></IconButton>
                <IconButton className="nav-link aspect-square" LinkComponent={Link} to="/"><Icons.MuiLinkedIn /></IconButton>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Typography className="text-2xl font-ff-montserrat font-medium text-jade-50">Quick links</Typography>
              <Stack spacing={0.5}>
                <Link className="nav-link" to="/home">home</Link>
                <Link className="nav-link" to="/about">about</Link>
                <Link className="nav-link" to="/services">services</Link>
                <Link className="nav-link" to="/contact">contact</Link>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Box className="p-4 bg-jade-400 copy-right-area">
        <Typography className="font-medium font-ff-lato leading-[1.2] tracking-[0.5px] text-center text-white">&copy; {year} Made with <Icons.MuiHeart className="text-red-500 text-xl" /> by 4Homes. All rights reserved. <Link className="nav-link">Privacy Policy</Link> | <Link className="nav-link">Terms of Service</Link></Typography>
      </Box>
    </Box>
  )
}

export default Footer